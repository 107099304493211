import React from 'react'
import { PageProps } from 'gatsby'
import { Footer, Head } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import img from '~/assets/img/img-carreiras.png'
import cn from 'classnames'

const Carreira = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>Carreiras Unicred</h1>
          <p>Você escolhe o caminho e constrói sua própria carreira</p>
        </section>
        <div className={cn(st.imgText, st.purple)}>
          <img src={img} alt="" />
          <p>
            O Plano de Cargos, Carreira e Remuneração do Sistema Unicred Conexão
            se fundamenta na crença de que{' '}
            <strong>
              o colaborador é quem decide sobre os caminhos que deseja seguir
            </strong>{' '}
            na Cooperativa, ficando sob sua responsabilidade identificar e
            desenvolver as competências necessárias para o cargo que almeja.
          </p>
        </div>
        <div className={st.marginTop}>
          <section
            className={cn(st.round, 'container', st.largeVersion, st.orange)}
          >
            <h2>O que é um plano de cargos, carreira e remuneração?</h2>
            <div>
              <p className={st.largeFont}>
                É o conjunto de normas e procedimentos que visa estabelecer e
                manter a estrutura salarial base (salário nominal) praticada na
                Cooperativa, em relação:
              </p>
              <ul>
                <li>
                  Aos demais cargos da Unicred Conexão, visando o equilíbrio
                  interno dos salários;
                </li>
                <li>
                  Aos mesmos cargos de outras organizações, visando o equilíbrio
                  externo.
                </li>
              </ul>
            </div>
          </section>
          <section
            className={cn(st.round, 'container', st.orange, st.largeVersion)}
          >
            <h2>
              principal foco <span className="block">do PCCR</span>
            </h2>
            <p>
              Orientar os colaboradores do Sistema Unicred Conexão quanto às
              possibilidades de ascensão profissional existentes dentro da
              cooperativa.
            </p>
            <div>
              <p className={st.largeFont}>
                <strong>
                  Qualquer plano de carreira é decorrente da estrutura
                  organizacional,
                </strong>{' '}
                pois é a estrutura (organograma) que define a hierarquia, a
                distribuição e o grau de especialização dos cargos.
              </p>
            </div>
          </section>
        </div>
        <section className="container">
          <h2>Carreiras do sistema Unicred Conexão</h2>
          <div className={st.careers}>
            <section>
              <h3>
                Carreiras <span className="block">de entrada</span>
              </h3>
              <ul className="ico-arrow">
                <li>Auxiliares</li>
                <li>Assistentes Administrativos</li>
                <li>Assistentes de Relacionamento</li>
                <li>Assistentes de Atendimento</li>
              </ul>
            </section>
            <section>
              <h3>
                Carreira <span className="block">técnica</span>
              </h3>
              <ul className="ico-arrow">
                <li>Analistas</li>
                <li>Assessores</li>
                <li>Especialistas</li>
                <li>Secretárias</li>
                <li>Gerentes de Relacionamento</li>
                <li>Gerentes da Experiência do Cooperado</li>
                <li>Gerente de Expansão</li>
                <li>Supervisores de Relacionamento</li>
              </ul>
            </section>
            <section>
              <h3>
                Carreira <span className="block">de gestão</span>
              </h3>
              <ul className="ico-arrow">
                <li>Coordenadores</li>
                <li>Gerentes de Agência</li>
                <li>Gerentes Regionais</li>
                <li>Superintendentes de Negócios</li>
                <li>Diretores</li>
              </ul>
            </section>
            <small>
              *Nosso plano de carreira estabelece que para o pleno desempenho
              das funções de liderança o profissional tenha desempenhado uma
              função técnica. Não há restrições para funções de lideranças
              realizarem movimentos retornando para funções técnicas.
            </small>
          </div>
        </section>
        <section className="container no-padding-top">
          <h2>Transição de carreira</h2>
          <section className={cn(st.round, st.purple, st.widthCustom)}>
            <h2>
              Em todos os processos de avaliação para transição de carreira você
              deverá refletir sobre:
            </h2>
            <div>
              <ul>
                <li>
                  Qual o meu <strong>nível de conhecimento</strong> considerando
                  as responsabilidades do cargo/função que almejo?
                </li>
                <li>
                  O quanto me sinto <strong>preparado</strong> para o nível de
                  autonomia técnica do cargo/função que almejo?
                </li>
                <li>
                  Qual o meu nível de cooperação quanto ao{' '}
                  <strong>repasse de conhecimentos</strong> para outras pessoas
                  (colegas internos, pares, cooperados, etc)?
                </li>
              </ul>
            </div>
          </section>
        </section>
      </main>
      <Footer location={location} />
    </>
  )
}

export default Carreira
